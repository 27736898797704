import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: HomeView
  },
  {
    path: '/hgzs',
    name: 'hgzs',
    redirect: '/hgzs/hgzsjs',
    component: () => import('@/views/hgzs/module/hgzs.vue'),
    children: [
      {
        path: 'hgzsjs',
        name: '航管助手介绍',
        component: () => import('@/views/hgzs/hgzsjs.vue')
      },
      {
        path: 'czjc',
        name: '操作教程',
        component: () => import('@/views/hgzs/czjc.vue')
      }
    ]
  },
  {
    path: '/cpjfw',
    name: 'cpjfw',
    redirect: '/cpjfw/cpjfw',
    component: () => import('@/views/cpjfw/module/cpjfw.vue'),
    children: [
      {
        path: 'cpjfw',
        name: '产品及服务',
        component: () => import('@/views/cpjfw/cpjfw.vue')
      },
      {
        path: 'cgal',
        name: '成功案例',
        component: () => import('@/views/cpjfw/cgal.vue')
      }
    ]
  },
  {
    path: '/hyfy',
    name: 'hyfy',
    redirect: '/hyfy/fyrd',
    component: () => import('@/views/hyfy/module/hyfy.vue'),
    children: [
      {
        path: 'gsxw',
        name: '公司新闻',
        component: () => import('@/views/hyfy/gsxw.vue')
      },
      {
        path: 'fyrd',
        name: '风云热点',
        component: () => import('@/views/hyfy/fyrd.vue')
      },
      {
        path: "kgfy",
        name: "空管风云",
        component: () => import('@/views/hyfy/kgfy.vue')
      },
      {
        name: "机场风云",
        path: "jcfy",
        component: () => import('@/views/hyfy/jcfy.vue')
      },
      {
        name: "航空公司风云",
        path: "hkgsfy",
        component: () => import('@/views/hyfy/hkgsfy.vue')
      },
      {
        name: "通航风云",
        path: "thfy",
        component: () => import('@/views/hyfy/thfy.vue')
      },
      {
        name: "无人机风云",
        path: "wrjfy",
        component: () => import('@/views/hyfy/wrjfy.vue')
      },
    ]
  },
  {
    path: '/hygj',
    name: 'hygj',
    redirect: '/hygj/kggd',
    component: () => import('@/views/hygj/module/hygj.vue'),
    children: [
      {
        name: "空管规定",
        path: "kggd",
        component: () => import('@/views/hygj/kggd.vue')
      },
      {
        name: "机场规定",
        path: "jcgd",
        component: () => import('@/views/hygj/jcgd.vue')
      },
      {
        name: "航空公司规定",
        path: "hkgsgd",
        component: () => import('@/views/hygj/hkgsgd.vue')
      },
      {
        name: "通航规定",
        path: "thgd",
        component: () => import('@/views/hygj/thgd.vue')
      },
      {
        name: "无人机规定",
        path: "wrjgd",
        component: () => import('@/views/hygj/wrjgd.vue')
      },
    ]
  },
  {
    path: '/jszl',
    name: 'jszl',
    redirect: '/jszl/gyjssz',
    component: () => import('@/views/jszl/module/jszl.vue'),
    children: [
      {
        name: "关于技术资料",
        path: "gyjszl",
        component: () => import('@/views/jszl/gyjszl.vue')
      },
      {
        name: "通讯设备",
        path: "txsb",
        component: () => import('@/views/jszl/txsb.vue')
      },
      {
        name: "导航设备",
        path: "dhsb",
        component: () => import('@/views/jszl/dhsb.vue')
      },
      {
        name: "监视设备",
        path: "jssb",
        component: () => import('@/views/jszl/jssb.vue')
      },
      {
        name: "记录仪设备",
        path: "jlysb",
        component: () => import('@/views/jszl/jlysb.vue')
      },
      {
        name: "电源设备",
        path: "dysb",
        component: () => import('@/views/jszl/dysb.vue')
      },
      {
        name: "防雷设备",
        path: "flsb",
        component: () => import('@/views/jszl/flsb.vue')
      },
    ]
  },
  {
    path: '/shfw',
    name: 'shfw',
    redirect: '/shfw/wmdcn',
    component: () => import('@/views/shfw/module/shfw.vue'),
    children: [
      {
        name: "我们的承诺",
        path: "wmdcn",
        component: () => import('@/views/shfw/wmdcn.vue')
      },
      {
        name: "用户登录",
        path: "yhdl",
        component: () => import('@/views/shfw/yhdl.vue')
      },
      {
        name: "用户设备",
        path: "yhsb",
        meta: { requiresAuth: true },
        component: () => import('@/views/shfw/yhsb.vue')
      },
      {
        name: "在线联系",
        path: "zxlx",
        meta: { requiresAuth: true },
        component: () => import('@/views/shfw/zxlx.vue')
      },
    ]
  },
  {
    path: '/qylm',
    name: 'qylm',
    redirect: '/qylm/gyqylm',
    component: () => import('@/views/qylm/module/qylm.vue'),
    children: [
      {
        name: "关于企业联盟",
        path: "gyqylm",
        component: () => import('@/views/qylm/gyqylm.vue')
      },
      {
        name: "会员注册",
        path: "hyzc",
        component: () => import('@/views/qylm/hyzc.vue')
      },
      {
        name: "会员推广",
        path: "hytg",
        component: () => import('@/views/qylm/hytg.vue')
      },
      {
        name: "市场动态",
        path: "scdt",
        component: () => import('@/views/qylm/scdt.vue')
      },
    ]
  },
  {
    path: '/gywm',
    name: 'gywm',
    redirect: '/gywm/gsjs',
    component: () => import('@/views/gywm/module/gywm.vue'),
    children: [
      {
        name: "公司介绍",
        path: "gsjs",
        component: () => import('@/views/gywm/gsjs.vue')
      },
      {
        name: "公司资质",
        path: "gszz",
        component: () => import('@/views/gywm/gszz.vue')
      },
      {
        name: "公司招聘",
        path: "gszp",
        component: () => import('@/views/gywm/gszp.vue')
      },
      {
        name: "联系我们",
        path: "lxwm",
        component: () => import('@/views/gywm/lxwm.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta?.requiresAuth);
  if (requiresAuth && !isAuthenticated) {
    next('/shfw/yhdl');
  } else {
    next();
  }
})

export default router
