<template>
  <div class="nav-title">
    <div class="nav-list">
      <ul>
        <li class="title-main">
          <router-link to="/">首页</router-link>
        </li>

        <li class="title-main" v-for="t_main in nav" :key="t_main.path">
          <router-link :to="t_main.path">{{ t_main.name }}</router-link>
          <template v-if="t_main.children">
            <ol>
              <li
                class="title-sub"
                v-for="t_sub in t_main.children"
                :key="t_sub.path"
              >
                <template v-if="t_sub.href">
                  <a :href="t_sub.href" :target="t_sub.target">{{
                    t_sub.name
                  }}</a>
                </template>
                <template v-else>
                  <router-link :to="t_sub.path">{{ t_sub.name }}</router-link>
                </template>
              </li>
            </ol>
          </template>
        </li>
      </ul>
    </div>
    <div class="nav-form">
      <el-form>
        <el-form-item>
          <el-input v-model="form.search">
            <img slot="suffix" src="../assets/image/iconfont-sousuo.png" />
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { nav } from "../utils/nav_list";
export default {
  name: "NavTitle",
  data() {
    return {
      select: "1",
      form: {
        search: "",
      },
      nav: nav,
    };
  },
};
</script>
<style lang="scss">
:root {
  --nav-title-height: 50px;
  --nav-title-df-color: #fff;
  --nav-form-input-width: 150px;
  --nav-form-input-height: 26px;
  --nax-form-input-border-radius: 0px;
}
.nav-title {
  // width: 100%;
  height: var(--nav-title-height);
  background: url(../assets/image/headimg.png) repeat-x;
  padding: var(--container-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-main {
    display: inline-block;
    line-height: var(--nav-title-height);
    position: relative;
    padding: 0 18px;
    &:hover {
      ol {
        display: block;
        width: 100%;
        li {
          height: 50px;
          box-sizing: content-box;
          line-height: 50px;
          padding: 0;
          background: url(../assets/image/ce-xiala.png);
          text-align: center;
          a {
            font-size: 14px;
          }
          &:hover {
            background: url(../assets/image/nav-selected.png) repeat-x;
            text-decoration: underline;
          }
        }
      }
      background: url(../assets/image/nav-selected.png) repeat-x;
    }
  }
  a {
    font-size: 18px;
    color: var(--nav-title-df-color);
  }
  ol {
    display: none;
    position: absolute;
    left: 0;
    z-index: 999;
    background: #fff;
    border-top: 1px solid #d4d4d4;
    box-sizing: border-box;
  }
  .nav-form {
    .el-form-item {
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
      padding-top: 6px;
    }
    .el-input-group__suffix {
      padding: 0 8px;
      border-radius: var(--nax-form-input-border-radius);
      background: var(--nav-title-df-color);
      border: none;
    }
    .el-form-item__content {
      width: var(--nav-form-input-width);
      height: var(--nav-form-input-height);
      line-height: var(--nav-form-input-height);
    }
    .el-input__inner {
      height: var(--nav-form-input-height);
      line-height: var(--nav-form-input-height);
      border-radius: var(--nax-form-input-border-radius);
    }
  }
}
.el-menu--horizontal > .el-menu-item {
  height: var(--nav-title-height);
}
</style>