<template>
  <div id="app">
    <div class="full_bk">
      <img src="./assets/image/full_bk.png" alt="full_bk" />
    </div>
    <div class="head_top">
      <a href="/">
        <img src="./assets/logo.png" alt="logo" />
      </a>
    </div>

    <NavTitle />
    <router-view />
    <FootView />
    <RightBox />
  </div>
</template>

<script>
import NavTitle from "./components/NavTitle.vue";
import FootView from "@/components/FootView.vue";
import RightBox from "./components/RightBox.vue";
export default {
  name: "App",
  components: { NavTitle, FootView, RightBox },
};
</script>

<style lang="scss">
#app {
  margin: 0;
  padding: 0;
  font: 14px/1.5 "Microsoft YaHei", Helvetica, Arial, sans-serif;
  // color: #333;
  // background: #f2f2f2;
  min-width: var(--min-body-width);
  max-width: var(--max-body-width);
  margin: 0 auto;
}
.full_bk {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.head_top {
  padding: var(--container-padding);
  padding-top: 20px;
  height: 80px;
  line-height: 40px;
  color: #666;
  box-sizing: border-box;
}
</style>
