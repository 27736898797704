export const nav = [
    {
        path: '/hgzs',
        name: '航管助手',
        component: "HGZS",
        children: [
            {
                path: '/hgzs/yhdl',
                name: '用户登录',
                component: "YHDL",
                href: 'https://www.actingtek.com.cn/FMA/login.html',
                target: '_blank',
            },
            {
                path: '/hgzs/hgzsjs',
                name: '航管助手介绍',
                component: "HGZSJS",
            },
            {
                path: '/hgzs/czjc',
                name: '操作教程',
                component: "CZJC",
            },
        ],
    },
    {
        path: '/cpjfw',
        name: '产品及服务',
        component: "CPJFW",
        children: [
            {
                path: '/cpjfw/cgal',
                name: '成功案例',
                component: "CGAL",
            }
        ]
    },
    {
        path: '/hyfy',
        name: '行业风云',
        component: 'HYFY',
        children: [
            {
                path: '/hyfy/gsxw',
                name: '公司新闻',
                component: 'GSXW',
            },
            {
                path: '/hyfy/fyrd',
                name: '风云热点',
                component: 'FYRD',
            },
            {
                path: '/hyfy/kgfy',
                name: '空管风云',
                component: 'KGFY',
            },
            {
                path: '/hyfy/jcfy',
                name: '机场风云',
                component: 'JCFY',
            },
            {
                path: '/hyfy/hkgsfy',
                name: '航空公司风云',
                component: 'HKGSFY',
            },
            {
                path: '/hyfy/thfy',
                name: '通航风云',
                component: 'THFY',
            },
            {
                path: '/hyfy/wrjfy',
                name: '无人机风云',
                component: 'WRJFY',
            }
        ]
    },
    {
        path: '/hygj',
        name: '行业规矩',
        component: 'HYGJ',
        children: [
            {
                path: '/hygj/kggd',
                name: '空管规定',
                component: 'KGGD',
            },
            {
                path: '/hygj/jcgd',
                name: '机场规定',
                component: 'JCGD',
            },
            {
                path: '/hygj/hkgsgd',
                name: '航空公司规定',
                component: 'HKGSGD',
            },
            {
                path: '/hygj/thgd',
                name: '通航规定',
                component: 'THGD',
            },
            {
                path: '/hygj/wrjgd',
                name: '无人机规定',
                component: 'WRJGD',
            }
        ]
    },
    {
        path: '/jszl',
        name: '技术资料',
        component: 'JSZL',
        children: [
            {
                path: '/jszl/gyjszl',
                name: '关于技术资料',
                component: 'GYJSZL',
            },
            {
                path: '/jszl/txsb',
                name: '通讯设备',
                component: 'TXSB',
            },
            {
                path: '/jszl/dhsb',
                name: '导航设备',
                component: 'DHSB',
            },
            {
                path: '/jszl/jssb',
                name: '监视设备',
                component: 'JSSB',
            },
            {
                path: '/jszl/jlysb',
                name: '记录仪设备',
                component: 'JLYSB',
            },
            {
                path: '/jszl/dysb',
                name: '电源设备',
                component: 'DYSB',
            },
            {
                path: '/jszl/flsb',
                name: '防雷设备',
                component: 'FLSB',
            }
        ]
    },
    {
        path: '/shfw',
        name: '售后服务',
        component: 'SHFW',
        children: [
            {
                path: '/shfw/wmdcn',
                name: '我们的承诺',
                component: 'WMDCN',
            },
            {
                path: '/shfw/yhdl',
                name: '用户登录',
                component: 'YHDL',
            },
            {
                path: '/shfw/yhsb',
                name: '用户设备',
                component: 'YHSB',
            },
            {
                path: '/shfw/zxlx',
                name: '在线联系',
                component: "ZXLX",
            }
        ]
    },
    {
        path: '/qylm',
        name: '企业联盟',
        component: 'QYLM',
        children: [
            {
                path: '/qylm/gyqylm',
                name: '关于企业联盟',
                component: 'GYQYLM',
            },
            {
                path: '/qylm/hyzc',
                name: '会员注册',
                component: 'HYZC',
            },
            {
                path: '/qylm/hytg',
                name: '会员推广',
                component: "HYTG",
            },
            {
                path: '/qylm/scdt',
                name: '市场动态',
                component: "SCDT",
            }
        ]
    },
    {
        path: '/gywm',
        name: '关于我们',
        component: 'GYWM',
        children: [
            {
                path: '/gywm/gsjs',
                name: '公司介绍',
                component: 'GSJS',
            },
            {
                path: '/gywm/gszz',
                name: '公司资质',
                component: 'GSZZ',
            },
            {
                path: '/gywm/gszp',
                name: '公司招聘',
                component: 'GSZP',
            },
            {
                path: '/gywm/lxwm',
                name: '联系我们',
                component: 'LXWM',
            }
        ]
    }
];