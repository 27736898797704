<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div
        v-for="item in swiperList"
        :key="item.id"
        class="swiper-slide"
        :style="`background-image: url(${item.imgUrl})`"
      ></div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "SwiperContainer",
  props: {
    swiperList: Array,
  },
  data() {
    return {
      swiper: null,
      // swiperList: [
      //   {
      //     id: 1,
      //     imgUrl: require("@/assets/image/home_swiper_1.jpg"),
      //   },
      //   {
      //     id: 2,
      //     imgUrl: require("@/assets/image/home_swiper_2.jpg"),
      //   },
      // ],
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        paginationClickable: true,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          paginationRender: function () {
            const pages = document.getElementsByClassName(
              "swiper-pagination-bullet"
            );
            for (let i = 0; i < pages.length; i++) {
              pages[i].onmouseover = function (e) {
                e.target.click();
              };
            }
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
:root {
  --swiper-button-pos: 6%;
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-bg-color: #fff;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-active-size: 14px;
}
.swiper-container {
  width: 1280px;
  height: 360px;
  margin: 0 auto;
  cursor: pointer;
  .swiper-slide {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f7f8fa;
  }
  .swiper-button-next {
    right: var(--swiper-button-pos);
  }
  .swiper-button-prev {
    left: var(--swiper-button-pos);
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0.5;
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    opacity: 1;
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-size);
    height: var(--swiper-pagination-bullet-size);
    background: var(--swiper-pagination-bullet-bg-color);
    opacity: 1;
    border: var(--swiper-pagination-bullet-border);
    display: inline-block;
  }
  .swiper-pagination-bullet-active {
    width: var(--swiper-pagination-bullet-active-size);
    height: var(--swiper-pagination-bullet-active-size);
  }
}
</style>