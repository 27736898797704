<template>
  <div class="home">
    <SwiperContainer :swiperList="swiperList" />
    <div class="flex-container">
      <div class="flex-item">
        <div>
          <img src="../assets/image/home/kjdz_qsxs.png" />
        </div>
        <div class="img-title">快捷定制 轻松享受</div>
      </div>
      <div class="flex-item">
        <div>
          <img src="../assets/image/home/jzdw_pwxs.png" />
        </div>
        <div class="img-title">精准定位 平稳显示</div>
      </div>
      <div class="flex-item">
        <div>
          <img src="../assets/image/home/txaq_fxsy.png" />
        </div>
        <div class="img-title">通讯安全 放心使用</div>
      </div>
    </div>
    <div class="flex-container link-container">
      <div class="flex-item link-item">
        <div class="line1">
          <div class="title">
            <h4>会员推广</h4>
          </div>
          <div class="more">
            <a href="#"> 查看更多>> </a>
          </div>
        </div>
        <div class="line2">
          <a href="#"> 会员招募中 </a>
        </div>
        <div class="line3">
          <a href="#">
            <img src="../assets/image/home/hytg.png" />
          </a>
        </div>
        <div class="line4"></div>
        <div class="line5">
          <a href="#"></a>
        </div>
      </div>
      <div class="flex-item link-item">
        <div class="line1">
          <div class="title">
            <h4>机场风云</h4>
          </div>
          <div class="more">
            <a href="#"> 查看更多>> </a>
          </div>
        </div>
        <div class="line2">
          <a href="#"> 天府国际机场带动效应 加快打 </a>
        </div>
        <div class="line3">
          <a href="#">
            <img src="../assets/image/home/jcfy_1.png" />
          </a>
        </div>
        <div class="line4"></div>
        <div class="line5">
          <a href="#"> 机场3条跑道2个航站楼同开建 2020年投用 </a>
        </div>
      </div>
      <div class="flex-item link-item">
        <div class="line1">
          <div class="title">
            <h4>导航设备</h4>
          </div>
          <div class="more">
            <a href="#"> 查看更多>> </a>
          </div>
        </div>
        <div class="line2">
          <a href="#"> 资料整理中 </a>
        </div>
        <div class="line3">
          <a href="#">
            <img src="../assets/image/home/dhsb.jpg" />
          </a>
        </div>
        <div class="line4"></div>
        <div class="line5">
          <a href="#"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperContainer from "@/components/SwiperContainer.vue";
export default {
  name: "HomeView",
  components: {
    SwiperContainer,
  },
  data() {
    return {
      swiperList: [
        {
          id: 1,
          imgUrl: require("@/assets/image/home_swiper_1.jpg"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/home_swiper_2.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.home {
  .flex-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 45px;
    gap: 72px;
    text-align: center;
    .flex-item {
      img {
        cursor: pointer;
      }
      .img-title {
        font-size: 20px;
        line-height: 20px;
        margin-top: 40px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .link-item {
      min-width: 352px;
      text-align: left;
      font-size: 15px;
      a {
        color: #0062b0;
        &:hover {
          text-decoration: underline;
        }
      }
      img {
        width: 222px;
        height: 138px;
      }
      .line1 {
        display: flex;
        justify-content: space-between;
        .title {
          color: #333333;
        }
        .more {
          font-size: 13px;
        }
      }
      .line2 {
        line-height: 36px;
        font-weight: 700;
      }
      .line4 {
        height: 1px;
        background: #000;
      }
      .line5 {
        font-weight: 700;
        height: 60px;
      }
    }
  }
  .link-container {
    gap: 12px;
  }
}
</style>