<template>
  <div class="foot">
    <div class="foot-left"></div>
    <div class="foot-container">
      <div class="link-img">
        <a href="http://www.caac.gov.cn/index.html">
          <img src="../assets/image/foot/zgmyhkj.png" />
        </a>
        <a href="http://www.carnoc.com/">
          <img src="../assets/image/foot/mhzxw.png" />
        </a>
        <a href="http://www.chinairport.com/">
          <img src="../assets/image/foot/zgmyjcw.png" />
        </a>
        <a href="http://www.caacnews.com.cn/">
          <img src="../assets/image/foot/zgmhw.png" />
        </a>
        <a href="http://www.tyhk.com.cn/">
          <img src="../assets/image/foot/zgtyhkw.png" />
        </a>
      </div>
      <div class="ban-quan">
        <p>地址：成都市高新区科园南路1号海特国际广场3号楼901号</p>
        <p>四川省行之行科技有限公司版权所有 蜀ICP备15032974号-1</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FootView",
};
</script>
<style lang="scss">
.foot {
  padding: 15px calc((var(--max-body-width) - var(--container-bak)) / 2);
  background: #00a7f1;
  color: #fff;
  margin-top: 30px;
  position: relative;
  box-sizing: border-box;
  .foot-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 210px;
    height: 140px;
    border-right: 1px solid #79b5f3;
    background: url(../assets/image/logofoot.png) no-repeat center center;
  }
  .foot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 40px;
    .link-img {
      display: flex;
      padding-left: 60px;
      gap: 6px;
      img {
        width: 120px;
        height: 35px;
      }
    }
    .ban-quan {
      margin-top: 20px;
    }
  }
}
</style>