<template>
  <div class="right_box">
    <a href="#" class="top" title="回到顶部"></a>
    <a
      href="https://www.actingtek.com.cn/FMA/login.html"
      class="help"
      title="航管助手"
      target="blank"
    ></a>
    <a
      href="http://wpa.qq.com/msgrd?v=3&uin=44118535&site=qq&menu=yes"
      class="toqq"
      title="联系我们"
    ></a>
  </div>
</template>
<script>
export default {
  name: "RightBox",
};
</script>
<style lang="scss">
.right_box {
  position: fixed;
  right: 10px;
  bottom: 100px;
  z-index: 9999;
  width: 45px;
  a {
    display: block;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    &.toqq {
      background: url(../assets/image/right_box/qq1.png);
      &:hover {
        background: url(../assets/image/right_box/qq.png);
      }
    }
    &.help {
      background: url(../assets/image/right_box/help1.png);
      &:hover {
        background: url(../assets/image/right_box/help.png);
      }
    }
    &.top {
      background: url(../assets/image/right_box/top1.png);
      &:hover {
        background: url(../assets/image/right_box/top.png);
      }
    }
  }
}
</style>